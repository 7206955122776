import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as yup from 'yup';

import {useFormatMessage} from 'hooks';
import UserForm, {passwordValidationSchema} from 'components/UserForm';
import {modifyUser} from 'state/actions/users';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    firstName: yup.string().required(),
    lastName: yup.string().notRequired(),
    password: passwordValidationSchema,
    phoneNumber: yup.string().required(),
    isAdmin: yup.boolean().notRequired(),
    address: yup.string().notRequired(),
});

const Profile = () => {
    const {userData} = useSelector((state) => ({
        userData: state.auth.userData,
    }), shallowEqual);

    const dispatch = useDispatch();

    const onSubmitHandler = (value) => {
        const newUser = {
            ...value,
            isEditing: true,
            isProfile: true,
            id: userData.id,
            encpwd: userData.encpwd,
            isAdmin: userData.isAdmin
        };
        dispatch(modifyUser(newUser));
    };

    return (<>
        <section className="hero is-hero-bar">
            <div className="hero-body">
                <h1 className="title">{useFormatMessage('Profile.profile')}</h1>
            </div>
        </section>
        <section className="section is-main-section">
            <UserForm
                isEditing
                isProfile
                user={userData}
                onSubmitHandler={onSubmitHandler}
                schema={schema}
            />
        </section>
    </>);
};

export default Profile;
