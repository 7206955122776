import {createAction} from 'redux-act';
import {toastr} from 'react-redux-toastr';
import CryptoJS from 'crypto-js';

import {FIREBASE_RESPONSE, firebaseError} from 'utils';
import firebase from 'firebase.js';
import {clearUsersDataLogout, fetchUsers} from './users';
import {fetchDocument} from '../api';

export const AUTH_SIGN_IN_INIT = createAction('AUTH_SIGN_IN_INIT');
export const AUTH_SIGN_IN_FAIL = createAction('AUTH_SIGN_IN_FAIL');

export const AUTH_RESTORE_SESSION_INIT = createAction('AUTH_RESTORE_SESSION_INIT');
export const AUTH_RESTORE_SESSION_SUCCESS = createAction('AUTH_RESTORE_SESSION_SUCCESS');
export const AUTH_RESTORE_AFTER_DELETE = createAction('AUTH_RESTORE_AFTER_DELETE');
export const AUTH_RESTORE_SESSION_FAIL = createAction('AUTH_RESTORE_SESSION_FAIL');

export const AUTH_LOGOUT_INIT = createAction('AUTH_LOGOUT_INIT');
export const AUTH_LOGOUT_SUCCESS = createAction('AUTH_LOGOUT_SUCCESS');

export const AUTH_SET_PASSWORD_INIT = createAction('AUTH_SET_PASSWORD_INIT');
export const AUTH_SET_PASSWORD_SUCCESS = createAction('AUTH_SET_PASSWORD_SUCCESS');
export const AUTH_SET_PASSWORD_FAIL = createAction('AUTH_SET_PASSWORD_FAIL');

export const AUTH_RESET_PASSWORD_INIT = createAction('AUTH_RESET_PASSWORD_INIT');
export const AUTH_RESET_PASSWORD_SUCCESS = createAction('AUTH_RESET_PASSWORD_SUCCESS');
export const AUTH_RESET_PASSWORD_FAIL = createAction('AUTH_RESET_PASSWORD_FAIL');

export const AUTH_CLEAN_UP = createAction('AUTH_CLEAN_UP');

export const AUTH_FETCH_USER_DATA_INIT = createAction('AUTH_FETCH_USER_DATA_INIT');
export const AUTH_FETCH_USER_DATA_SUCCESS = createAction('AUTH_FETCH_USER_DATA_SUCCESS');
export const AUTH_FETCH_USER_DATA_FAIL = createAction('AUTH_FETCH_USER_DATA_FAIL');

export const AUTH_CHANGE_PASSWORD_INIT = createAction('AUTH_CHANGE_PASSWORD_INIT');
export const AUTH_CHANGE_PASSWORD_SUCCESS = createAction('AUTH_CHANGE_PASSWORD_SUCCESS');
export const AUTH_CHANGE_PASSWORD_FAIL = createAction('AUTH_CHANGE_PASSWORD_FAIL');

export const AUTH_UPDATE_USER_DATA = createAction('AUTH_UPDATE_USER_DATA');

export const AUTH_PROVIDER_INIT = createAction('AUTH_PROVIDER_INIT');

export const AUTH_PROVIDER_SUCCESS = createAction('AUTH_PROVIDER_SUCCESS');

export const AUTH_PROVIDER_FAIL = createAction('AUTH_PROVIDER_FAIL');

export const logout = () => {
    return async (dispatch) => {
        console.log("LOGOUT");
        dispatch(AUTH_LOGOUT_INIT());

        dispatch(clearUsersDataLogout());
        await firebase.auth().signOut();

        dispatch(AUTH_LOGOUT_SUCCESS());
    };
};

export const verifyAuth = () => {
    return (dispatch, getState) => {
        firebase.auth().onAuthStateChanged((user) => {
            console.log("AUTH STATE CHANGED");
            console.log(user);
            const isDeleting = getState().users.deleting;
            console.log("IS DELETEING", isDeleting);
            if (isDeleting && !user) {
                const adminData = getState().auth.userData;
                const adminKey = adminData.enckey;
                const adminPassword = CryptoJS.AES.decrypt(adminData.encpwd, adminKey.toString()).toString(CryptoJS.enc.Utf8);
                const credentialAdmin = firebase.auth.EmailAuthProvider.credential(adminData.email, adminPassword);
                firebase.auth().signInWithCredential(credentialAdmin).then(value => {
                    console.log(value);
                    dispatch(fetchUsers());
                });
                return dispatch(AUTH_RESTORE_AFTER_DELETE());
            }
            dispatch(AUTH_RESTORE_SESSION_INIT());

            if (user !== null) {
                return dispatch(AUTH_RESTORE_SESSION_SUCCESS());
            }

            dispatch(AUTH_RESTORE_SESSION_FAIL());
            return dispatch(logout());
        });
    };
};

export const fetchUserData = () => {
    return async (dispatch) => {
        dispatch(AUTH_FETCH_USER_DATA_INIT());

        const {uid} = firebase.auth().currentUser;

        let user;
        console.log("UID", uid);
        try {
            user = await fetchDocument('users', uid);
        } catch (error) {
            dispatch(logout());
            return dispatch(AUTH_FETCH_USER_DATA_FAIL({error}));
        }
        console.log("USER INFO");
        console.log(user);
        if (!user) {
            // await createDocument('users', uid, {'role': "admin",'isAdmin':true});
            return dispatch(logout());
        }

        return dispatch(AUTH_FETCH_USER_DATA_SUCCESS({
            id: uid, ...user,
        }));
    };
};

export const checkUserData = () => {
    return (dispatch, getState) => {
        const {id} = getState().auth.userData;

        if (!id) {
            dispatch(fetchUserData());
        }
    };
};

export const auth = (email, password) => {
    return async (dispatch, getState) => {
        dispatch(AUTH_SIGN_IN_INIT());
        const {locale} = getState().preferences;
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            return dispatch(AUTH_SIGN_IN_FAIL({error: errorMessage}));
        }

        const userInfo = firebase.auth().currentUser;

        if (userInfo.disabled) {
            const errorMessage = firebaseError(FIREBASE_RESPONSE.USER_DISABLED, locale);
            return dispatch(AUTH_SIGN_IN_FAIL({error: errorMessage}));
        }

        return dispatch(fetchUserData());
    };
};

export const setPassword = (email, password, url) => {
    return async (dispatch, getState) => {
        dispatch(AUTH_SET_PASSWORD_INIT());
        const {locale} = getState().preferences;

        try {
            await firebase.auth().signInWithEmailLink(email, url);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            return dispatch(AUTH_SET_PASSWORD_FAIL({error: errorMessage}));
        }

        const user = firebase.auth().currentUser;

        try {
            await user.updatePassword(password);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            return dispatch(AUTH_SET_PASSWORD_FAIL({error: errorMessage}));
        }

        dispatch(AUTH_SET_PASSWORD_SUCCESS());

        return dispatch(fetchUserData());
    };
};

export const resetPassword = (email) => {
    return async (dispatch, getState) => {
        dispatch(AUTH_RESET_PASSWORD_INIT());
        const {locale} = getState().preferences;

        try {
            await firebase.auth().sendPasswordResetEmail(email);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            return dispatch(AUTH_RESET_PASSWORD_FAIL({error: errorMessage}));
        }

        return dispatch(AUTH_RESET_PASSWORD_SUCCESS());
    };
};

export const authCleanUp = () => (dispatch) => dispatch(AUTH_CLEAN_UP());

export const changeUserPassword = (userInfo, newPassword) => {
    return async (dispatch, getState) => {
        dispatch(AUTH_CHANGE_PASSWORD_INIT());
        const {locale} = getState().preferences;
        const adminData = getState().auth.userData;
        const adminKey = adminData.enckey;
        const adminPassword = CryptoJS.AES.decrypt(adminData.encpwd, adminKey.toString()).toString(CryptoJS.enc.Utf8);
        const userFullData = userInfo.id === getState().auth.userData.id ? getState().auth.userData : getState().users.data.find(value => value.id === userInfo.id);

        console.log(userFullData);
        console.log(userInfo);
        console.log(adminKey);
        const rawPassword = CryptoJS.AES.decrypt(userFullData.encpwd, adminKey.toString()).toString(CryptoJS.enc.Utf8);
        console.log(rawPassword);
        const credential = firebase.auth.EmailAuthProvider.credential(userFullData.email, rawPassword);

        let encryptedPassword;
        try {
            const user = await firebase.auth().signInWithCredential(credential);
            console.log(user);
            encryptedPassword = CryptoJS.AES.encrypt(newPassword, adminKey.toString()).toString();
            await user.user.updatePassword(newPassword);
        } catch (error) {
            console.log(error);
            const errorMessage = firebaseError(error, locale);
            toastr.error('', errorMessage);
            return dispatch(AUTH_CHANGE_PASSWORD_FAIL({error: errorMessage}));
        }
        const credentialAdmin = firebase.auth.EmailAuthProvider.credential(adminData.email, adminPassword);
        await firebase.auth().signInWithCredential(credentialAdmin);
        toastr.success('', 'Password changed successfully');
        dispatch(AUTH_CHANGE_PASSWORD_SUCCESS());
        return encryptedPassword;
    };
};