/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import {yupResolver} from '@hookform/resolvers';
import * as yup from 'yup';
import paths from 'pages/Router/paths';
import {usersCleanUp} from 'state/actions/users';
import {useFormatMessage} from 'hooks';
import ErrorMessage from 'components/ErrorMessage';

import './UserForm.scss';

export const passwordValidationSchema = yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
    );

const UserForm = ({isEditing, isProfile, user, onSubmitHandler, schema}) => {
    const {loading, success} = useSelector((state) => ({
        loading: state.users.loading, success: state.users.success,
    }), shallowEqual);

    const dispatch = useDispatch();

    const {register, handleSubmit, errors, setValue} = useForm({
        defaultValues: {...user}, resolver: yupResolver(schema),
    });

    useEffect(() => {
        return () => dispatch(usersCleanUp());
    }, [dispatch, success, setValue]);

    const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');
    const goBackMessage = useFormatMessage('UserForm.goBack');
    const emailMessage = useFormatMessage('UserForm.email');

    function onErrors(err) {
        console.log("FORM ERRORS");
        console.log(err);
    }

    return (<>
        <div className="tile is-ancestor">
            <div className="tile is-parent">
                <div className="card tile is-child">
                    <header className="card-header">
                        <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default"/>
                </span>
                            {useFormatMessage('UserForm.userInfo')}
                        </p>
                    </header>
                    <div className="card-content">
                        <form onSubmit={handleSubmit(onSubmitHandler, onErrors)}>
                            {isEditing ? (<div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">{emailMessage}</label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                type="text"
                                                readOnly="readOnly"
                                                className="input is-static"
                                                name="email"
                                                ref={register}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>) : (<>
                                <div className="field is-horizontal">
                                    <div className="field-label is-normal">
                                        <label className="label">{emailMessage}</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <div className="control">
                                                <input
                                                    className={classNames(`input`, {
                                                        'is-danger': errors.email,
                                                    })}
                                                    ref={register}
                                                    name="email"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {errors.email && (<div className="field is-horizontal">
                                    <div className="field-label is-normal"/>
                                    <div className="field-body">
                                        <ErrorMessage text={invalidEmailMessage}/>
                                    </div>
                                </div>)}
                            </>)}

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {useFormatMessage('UserForm.firstName')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                name="firstName"
                                                id="firstName"
                                                className={classNames('input', {
                                                    'is-danger': errors.firstName,
                                                })}
                                                ref={register}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {errors.firstName && (<div className="field is-horizontal">
                                <div className="field-label is-normal"/>
                                <div className="field-body">
                                    <ErrorMessage/>
                                </div>
                            </div>)}

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {useFormatMessage('UserForm.lastName')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                name="lastName"
                                                id="lastName"
                                                className={classNames('input', {
                                                    'is-danger': errors.lastName,
                                                })}
                                                ref={register}
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {errors.lastName && (<div className="field is-horizontal">
                                <div className="field-label is-normal"/>
                                <div className="field-body">
                                    <ErrorMessage/>
                                </div>
                            </div>)}

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {useFormatMessage('UserForm.address')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                className="input"
                                                type="text"
                                                ref={register}
                                                name="address"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {errors.phoneNumber && (<div className="field is-horizontal">
                                <div className="field-label is-normal"/>
                                <div className="field-body">
                                    <ErrorMessage/>
                                </div>
                            </div>)}

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {useFormatMessage('UserForm.phoneNumber')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                className="input"
                                                type="phone"
                                                ref={register}
                                                name="phoneNumber"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {errors.password && (<div className="field is-horizontal">
                                <div className="field-label is-normal"/>
                                <div className="field-body">
                                    <ErrorMessage text={errors.password.message}/>
                                </div>
                            </div>)}

                            <div className="field is-horizontal">
                                <div className="field-label is-normal">
                                    <label className="label">
                                        {useFormatMessage('UserForm.password')}
                                    </label>
                                </div>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="control">
                                            <input
                                                className="input"
                                                type="password"
                                                ref={register}
                                                name="password"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr/>
                            <div className="field is-horizontal">
                                <div className="field-label"/>
                                <div className="field-body">
                                    <div className="field">
                                        <div className="field is-grouped">
                                            <div className="control">
                                                <button
                                                    type="submit"
                                                    className={`button is-primary ${loading && 'is-loading'}`}
                                                >
                                                    <span>{useFormatMessage('UserForm.submit')}</span>
                                                </button>
                                            </div>
                                            {!isProfile && (<Link to={paths.USERS} className="button">
                                                {goBackMessage}
                                            </Link>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

UserForm.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        address: PropTypes.string,
        phoneNumber: PropTypes.string,
        password: PropTypes.string,
        email: PropTypes.string.isRequired,
    }).isRequired, onSubmitHandler: PropTypes.func.isRequired, // eslint-disable-next-line react/forbid-prop-types
    schema: PropTypes.object.isRequired, isEditing: PropTypes.bool, isProfile: PropTypes.bool,
};

UserForm.defaultProps = {
    isEditing: false, isProfile: false,
};

export default UserForm;
