import {createAction} from 'redux-act';
import {toastr} from 'react-redux-toastr';
import CryptoJS from "crypto-js";
import {firebaseError} from 'utils';
import firebase from 'firebase.js';
import {AUTH_UPDATE_USER_DATA, changeUserPassword, checkUserData} from './auth';
import {createDocument, deleteDocument, fetchCollection, fetchDocument, updateDocument,} from '../api';


export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction('USERS_FETCH_DATA_SUCCESS');
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction('USERS_DELETE_USER_SUCCESS');
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction('USERS_CREATE_USER_SUCCESS');
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction('USERS_MODIFY_USER_SUCCESS');
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');

export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

export const fetchUsers = (userId = '') => {
    return async (dispatch, getState) => {
        dispatch(checkUserData());

        dispatch(USERS_FETCH_DATA_INIT());

        if (userId) {
            let user;
            try {
                user = await fetchDocument('users', userId);
            } catch (error) {
                toastr.error('', error);
                return dispatch(USERS_FETCH_DATA_FAIL({error}));
            }

            if (!user) {
                const errorMessage = 'User not available';
                toastr.error('', errorMessage);
                return dispatch(USERS_FETCH_DATA_FAIL({error: errorMessage}));
            }

            const users = getState().users.data;
            console.log("FETCHED USERS");
            console.log(users);
            users.push(user);

            return dispatch(USERS_FETCH_DATA_SUCCESS({
                data: users,
            }));
        }

        const {id} = getState().auth.userData;

        let users;

        try {
            users = await fetchCollection('users');
        } catch (error) {
            toastr.error('', error);
            return dispatch(USERS_FETCH_DATA_FAIL({error}));
        }

        return dispatch(USERS_FETCH_DATA_SUCCESS({
            data: users.filter((user) => user.id !== id),
        }));
    };
};

export const deleteUser = (id) => {
    return async (dispatch, getState) => {
        dispatch(USERS_DELETE_USER_INIT());
        const {locale} = getState().preferences;
        const userFullData = getState().users.data.find(value => value.id === id);
        const adminData = getState().auth.userData;
        const adminKey = adminData.enckey;
        const adminPassword = CryptoJS.AES.decrypt(adminData.encpwd, adminKey.toString()).toString(CryptoJS.enc.Utf8);

        const encpwd = CryptoJS.AES.decrypt(userFullData.encpwd, adminKey.toString()).toString(CryptoJS.enc.Utf8);
        const credential = firebase.auth.EmailAuthProvider.credential(userFullData.email, encpwd);
        try {
            const user = await firebase.auth().signInWithCredential(credential);
            await deleteDocument('users', id);
            await user.user.delete();
        } catch (error) {
            console.log("DELETE USER FAILDEEEE");
            console.log(error);
            const errorMessage = firebaseError(error.code, locale);
            toastr.error('', errorMessage);
            return dispatch(USERS_DELETE_USER_FAIL({
                error: errorMessage,
            }));
        }
        const credentialAdmin = firebase.auth.EmailAuthProvider.credential(adminData.email, adminPassword);
        await firebase.auth().signInWithCredential(credentialAdmin);
        toastr.success('', 'The user was deleted.');
        dispatch(fetchUsers());

        return dispatch(USERS_DELETE_USER_SUCCESS({id}));
    };
};

export const clearUsersDataLogout = () => {
    return (dispatch) => {
        dispatch(USERS_CLEAR_DATA_LOGOUT());
    };
};

function createUserAuth(email, password) {
    return new Promise((resolve, reject) => {
        return firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((user) => resolve(user))
            .catch((err) => reject(err));
    });
}

export const createUser = ({
                               firstName, lastName, email, address, phoneNumber, password,
                           }) => {
    return async (dispatch, getState) => {
        dispatch(USERS_CREATE_USER_INIT());
        const {locale} = getState().preferences;
        const adminKey = getState().auth.userData.enckey;
        const encpwd = CryptoJS.AES.encrypt(password, adminKey.toString()).toString();
        console.log("PASSWOORDDD");
        console.log(encpwd);
        let createdUser;
        try {
            createdUser = await createUserAuth(email, password);
        } catch (error) {
            const errorMessage = firebaseError(error.message, locale);
            toastr.error('', errorMessage);
            return dispatch(USERS_CREATE_USER_FAIL({
                error: errorMessage,
            }));
        }

        const {uid} = createdUser.user;

        const userData = {firstName, lastName, email, address, phoneNumber, encpwd};

        const createUserDbTask = createDocument('users', uid, userData);

        try {
            await Promise.all([createUserDbTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toastr.error('', errorMessage);
            return dispatch(USERS_CREATE_USER_FAIL({
                error: errorMessage,
            }));
        }

        toastr.success('', 'User created successfully');
        dispatch(fetchUsers());
        return dispatch(USERS_CREATE_USER_SUCCESS({user: createdUser.user}));
    };
};

export const modifyUser = (userInfo) => {
    const {
        firstName, lastName, email, address, phoneNumber, password, id, isEditing, isProfile,
    } = userInfo;
    return async (dispatch, getState) => {
        dispatch(USERS_MODIFY_USER_INIT());
        const {locale} = getState().preferences;
        let encpwd;
        if (password) {
            encpwd = await changeUserPassword(userInfo, password)(dispatch, getState);
            console.log("NEW PASSWORD");
            console.log(encpwd);
            if (!(encpwd && typeof encpwd === 'string')) {
                return dispatch(USERS_MODIFY_USER_FAIL({
                    error: "Cannot change password",
                }));
            }
        }
        // var cred = firebase.auth.EmailAuthProvider.credential(
        //     user.email, currentPassword);
        const userData = {
            firstName, lastName, email, address, phoneNumber, encpwd,
        };
        const updateUserDbTask = updateDocument('users', id, userData);

        try {
            await Promise.all([updateUserDbTask]);
        } catch (error) {
            const errorMessage = firebaseError(error.code, locale);
            toastr.error('', errorMessage);
            return dispatch(USERS_MODIFY_USER_FAIL({
                error: errorMessage,
            }));
        }
        const adminData = getState().auth.userData;
        const {uid} = firebase.auth().currentUser;

        if (id === uid) {
            dispatch(AUTH_UPDATE_USER_DATA({...userData, id, isAdmin: adminData.isAdmin, enckey: adminData.enckey}));
        }

        if (isProfile) {
            toastr.success('', 'Profile updated successfully');
        } else if (isEditing) {
            toastr.success('', 'User updated successfully');
        }

        return dispatch(USERS_MODIFY_USER_SUCCESS({...userData, id, isAdmin: adminData.isAdmin, enckey: adminData.enckey}));
    };
};

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());
